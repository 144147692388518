import Cookies from 'js-cookie';

const getBaseUrl = () => {
    const env = process.env.NODE_ENV
    if(env === 'production') {
        return ''
    } else {
        return 'http://localhost:8000'
    }
}


/////////////// 인증관련 API //////////////////
const getTokens = (type) => {
    if (type === 'refresh') {
        const refreshToken = localStorage.getItem('refresh-token');
        return {'refresh-token': refreshToken};
    } else if (type === 'access') {
        const accessToken = localStorage.getItem('access-token');
        return {'access-token': accessToken};
    }
}

const getRefresh = async () => {
    const refreshToken = getTokens("refresh");

    const apiUrl = `${getBaseUrl()}/api/auth/refresh`;

    try {
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${refreshToken['refresh-token']}`
            },
        });
        const data = await response.json();

        localStorage.setItem('access-token', response.headers.get('access-token'));
        localStorage.setItem('refresh-token', response.headers.get('refresh-token'));

        return data;
    } catch (error) {
        throw error;
    }
};

const postSignIn = async (username, password) => {
    const apiUrl = `${getBaseUrl()}/api/auth/sign_in`;

    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user_name: username,
                user_password: password
            }),
            credentials: 'include'
        });
        const data = await response.json();

        localStorage.setItem('access-token', response.headers.get('access-token'));
        localStorage.setItem('refresh-token', response.headers.get('refresh-token'));
        
        return data;
    } catch (error) {
        throw error;
    }
};
//////////////// 인증관련 API 끝 //////////////////


const getTemplates = async () => {
    const apiUrl = `${getBaseUrl()}/api/doc/templates`;

    try {
        const response = await fetch(apiUrl);
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
};

const postRun = async function* (inputQuery, userType, isDisabled) {
    
    const apiUrl = `${getBaseUrl()}/api/run`;
    const accessToken = getTokens('access');
    
    const headers = {
        'Content-Type': 'application/json'
    };
    
    if (accessToken['access-token'] !== null) {
        headers['Authorization'] = `Bearer ${accessToken['access-token']}`;
    }

    const response = await fetch(apiUrl, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            query: inputQuery,
            user_type: userType,
            disabled_type: isDisabled
        }),
        credentials: 'include'
    })

    // console.log(response)

    let reader = response.body.getReader();
    let decoder = new TextDecoder('utf-8');
    let buffer = '';

    while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        buffer += decoder.decode(value, { stream: true });

        while (true) {
            const newlineIndex = buffer.indexOf('\n');
            if (newlineIndex === -1) break;

            const line = buffer.slice(0, newlineIndex);
            buffer = buffer.slice(newlineIndex + 1);

            if (line.trim()) {
                try {
                    let token = JSON.parse(line);
                    // console.log(token);
                    yield token;
                } catch (e) {
                    console.error('Error parsing JSON : ', e)
                }                    
            }
        }
    }
};


const getData = async () => {

    const apiUrl = `${getBaseUrl()}/api/load_data`;
    const accessToken = getTokens('access');

    let headers = {};
    if (accessToken['access-token'] !== null) {
        headers['Authorization'] = `Bearer ${accessToken['access-token']}`;
    }
    
    try {
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: headers,
            credentials: 'include'
        })

        console.log(typeof response)

        return response.json()
    } catch (error) {
        console.error('Error downloading the document:', error);
        throw error;
    }

}


const getDownloadFile = async (fileId) => {

    const apiUrl = `${getBaseUrl()}/api/download_file?file_id=${fileId}`;
    
    try {
        const response = await fetch(apiUrl, {
            method: 'GET',
            credentials: 'include'
        })
    
        const blob = await response.blob();
        const text = await blob.text(); // Get the text content of the blob
            
        // 헤더를 직접 읽기
        const encodedFileName = response.headers.get('Content-Disposition');
        console.log('Content-Disposition:', encodedFileName); 
        if (!encodedFileName) {
            throw new Error('Content-Disposition header is missing');
        }
    
        const fileName = decodeURIComponent(encodedFileName);
        console.log("fileName", fileName);
    
        if (blob) {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = fileName; // You can use the actual filename if available
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        }
    
        // Return both the text content and the blob
        return { success: true, content: text, blob: blob };

    } catch (error) {
        console.error('Error downloading the document:', error);
        throw error;
    }
}

/////////////// ChatbotPage 끝 /////////////////////


/////////////// Playground Page 끝 /////////////////

export {
    postRun,
    getDownloadFile,
    getTemplates,
    postSignIn,
    getRefresh,
    getData,
};
