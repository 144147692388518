import { Button, HStack, IconButton, useColorModeValue } from "@chakra-ui/react";
import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from "react-icons/md"; // to use 1 Arrow 
import { useEffect, useState } from "react";

const Pagination = ({ selectedPage, setSelectedPage, allRowsNumber, rowsNumberPerPage }) => {
  const allNumberBoxes = Math.ceil(allRowsNumber / rowsNumberPerPage);
  // const boxesBg = useColorModeValue("gray.200", "gray.700");
  const activeColor = useColorModeValue("black", "white");
  const inactiveColor = useColorModeValue("gray", "gray");

  // numbox pagination
  const pagenationLength = 10;
  const [nowNumBoxPage, setNowNumBoxPage] = useState(Math.floor(selectedPage / pagenationLength));

  const lastNumBoxPage = Math.ceil(allNumberBoxes / pagenationLength) - 1;

  // data가 바뀔 때 setSelectedPage(1)이 실행되면서 NowNumBpxPage도 0으로 초기화   
  useEffect(() => {
    setNowNumBoxPage(Math.floor((selectedPage - 1) / pagenationLength));
  }, [selectedPage])


  return (
    <>

      {allNumberBoxes > 1 && (

        <HStack>
          <IconButton
            icon={<MdKeyboardDoubleArrowLeft size="2rem" />}
            onClick={() => { setSelectedPage(1); setNowNumBoxPage(0); }}
            borderRadius='13px'
            bg='transparent'
          />

          <IconButton
            icon={<MdKeyboardArrowLeft size="2rem" />}
            onClick={() => {
              if (nowNumBoxPage > 0) {
                setSelectedPage((nowNumBoxPage - 1) * pagenationLength + 1);
                setNowNumBoxPage(nowNumBoxPage - 1)
              }
            }}
            borderRadius='13px'
            bg='transparent'
          />
          {Array.from({ length: allNumberBoxes }, (_, i) => (
            (i >= (nowNumBoxPage) * pagenationLength) && (i < (nowNumBoxPage + 1) * pagenationLength) ?
              <Button
                key={i}
                bg='transparent'
                fontSize="20px"
                onClick={() => setSelectedPage(i + 1)}
                color={selectedPage === i + 1 ? activeColor : inactiveColor}
                borderRadius='13px'
              >
                {i + 1}
              </Button>
              : <></>
          ))}
          <IconButton
            icon={<MdKeyboardArrowRight size="2rem" />}
            onClick={() => {
              if (nowNumBoxPage < lastNumBoxPage) {
                setSelectedPage((nowNumBoxPage + 1) * pagenationLength + 1);
                setNowNumBoxPage(nowNumBoxPage + 1);
              }
            }}
            borderRadius='13px'
            bg='transparent'
          />
          <IconButton
            icon={<MdKeyboardDoubleArrowRight size="2rem" />}
            onClick={() => { setSelectedPage(allNumberBoxes); setNowNumBoxPage(lastNumBoxPage); }}
            borderRadius='13px'
            bg='transparent'
          />
        </HStack>
      )}
    </>
  );
}

export default Pagination;