import { useState, useCallback, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import {
    Box, Text, Container, Center, Image, Input,
    Flex, useToast, IconButton, HStack, InputGroup, InputRightElement,
    useDisclosure
} from "@chakra-ui/react";
import { IoIosSend } from 'react-icons/io';
import { HiEye, HiEyeOff } from 'react-icons/hi';
import { MdLogin } from "react-icons/md";
import { useGlobal } from '../contexts/GlobalContext';

import { postSignIn } from '../Api';
import {
    calculateAccessTokenExpireTime, calculateRefreshTokenExpireTime,
    calculateSessionExpireTime
} from '../components/TimeCalculatingComponent';

const SignInPage = ({onCloseDrawer}) => {
    const toast = useToast();
    const navigate = useNavigate();

    const { setIsSignedIn } = useGlobal();

    const [userId, setuserId] = useState('');
    const [userSecret, setUserSecret] = useState('');

    const { isOpen, onToggle } = useDisclosure();
    const inputRef = useRef(null)

    const onClickReveal = () => {
        onToggle()
        if (inputRef.current) {
            inputRef.current.focus({ preventScroll: true })
        }
    }

    const handlePostSignIn = useCallback(async () => {
        const username = userId;
        const password = userSecret;

        try {
            const data = await postSignIn(username, password);

            if (data['result'] === 1) {
                toast({
                    title: 'Sign In Success',
                    description: '로그인 성공',
                    status: 'success',
                    isClosable: true,
                    position: 'top'
                });

                setIsSignedIn(true);

                localStorage.setItem('session-exp', calculateSessionExpireTime());
                localStorage.setItem('access-token-exp', calculateAccessTokenExpireTime());
                localStorage.setItem('refresh-token-exp', calculateRefreshTokenExpireTime());

                onCloseDrawer();

                navigate('/admin')

                return;
            } else {
                toast({
                    title: 'Sign In Failed',
                    description: '아이디, 비밀번호를 확인하세요.',
                    status: 'error',
                    isClosable: true,
                    position: 'top'
                });
                return;
            }

        } catch (error) {
            toast({
                title: 'Fetch Failed',
                description: `Error: ${error}`,
                status: 'error',
                isClosable: true,
                position: 'top'
            });
        }
    }, [userId, userSecret, navigate, setIsSignedIn, toast]);

    return (
        <Container maxW='' size='container.3xl'>
            <Container maxW='container.sm' h='80vh' alignContent='center'>

                <Flex direction='column'>
                    <Box>
                        <Center marginBottom="3rem">
                            <Image
                                height='10rem'
                                width= '11rem'
                                src='/slogan/positive.png'
                                alt='slogan'
                                // marginLeft="4.5rem"
                            />
                        </Center>
                    </Box>
                        <Box>
                            <Box>
                                <Input
                                    placeholder='아이디를 입력하세요.'
                                    _placeholder={{ color : "#A6A9B6" }}
                                    _hover={{ borderColor : "#718096" }}
                                    borderRadius='13px'
                                    borderWidth='1px'
                                    textColor="black"
                                    borderColor='#A6A9B6'
                                    bgColor='white'
                                    onChange={(e) => {
                                        setuserId(e.target.value)
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            handlePostSignIn();
                                        }
                                    }}
                                />
                            </Box>

                            <Box mt='3'>
                                <HStack>
                                    <InputGroup>
                                        <InputRightElement>
                                            <IconButton
                                                variant="text"
                                                color="black"
                                                aria-label={isOpen ? 'Mask password' : 'Reveal password'}
                                                icon={isOpen ? <HiEyeOff /> : <HiEye />}
                                                onClick={onClickReveal}
                                            />
                                        </InputRightElement>
                                        <Input
                                            placeholder='비밀번호를 입력하세요.'
                                            _placeholder={{ color : "#A6A9B6" }}
                                            _hover={{ borderColor : "#718096" }}
                                            borderRadius='13px'
                                            borderWidth='1px'
                                            borderColor='#A6A9B6'
                                            textColor="black"
                                            id="password"
                                            name="password"
                                            type={isOpen ? 'text' : 'password'}
                                            required
                                            bgColor='white'
                                            onChange={(e) => setUserSecret(e.target.value)}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    handlePostSignIn();
                                                }
                                            }}
                                        />
                                    </InputGroup>
                                </HStack>
                            </Box>
                        </Box>

                        <IconButton
                            mt='3'
                            icon={<MdLogin />}
                            colorScheme='blue'
                            borderRadius='13px'
                            color="black"
                            onClick={handlePostSignIn}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handlePostSignIn();
                                }
                            }}
                            width="100%"
                            marginTop="2rem"
                        />

                </Flex>

            </Container>
        </Container>
    );
}

export default SignInPage;
