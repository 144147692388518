import { postRun } from '../Api';
import { useState, useEffect, useCallback, useRef } from 'react';

import '../styles/ChatPage.css';

import {
    Flex, Box, Spacer, Container, HStack, Textarea, Button, Text,
    Tabs, TabList, TabPanels, Tab, TabPanel, useToast, IconButton, Image, Icon, Card,
    VStack, Divider,
    Center
} from '@chakra-ui/react';

import { RepeatIcon, PhoneIcon, ExternalLinkIcon } from '@chakra-ui/icons'
import { IoIosSend } from 'react-icons/io';
import { FcTodoList, FcCalendar, FcDecision, FcKey, FcFeedback, FcMoneyTransfer, FcSimCardChip, FcRefresh } from "react-icons/fc";

import LoadingOverlay from '../components/LoadingOverlayComponent';
import { usePageColor } from '../contexts/ColorContext';

import { newTheme } from '../components/mdstyle'
import ChakraUIRenderer from 'chakra-ui-markdown-renderer';
import Markdown from "react-markdown";

const ChatbotPage = ({ checkExpireTime, chatReloadTrigger, setChatReloadTrigger }) => {

    const colors = usePageColor('chatbot');

    const toast = useToast();
    const inputRef = useRef(null);

    const [inputQuery, setInputQuery] = useState("");
    const [textQuery, setTextQuery] = useState("");
    const [viewInputQuery, setViewInputQuery] = useState("테스트 문의 내용");
    const [postRunTriggered, setPostRunTriggered] = useState(false);

    const [queryResponses, setQueryResponses] = useState('');

    const [isHandlingEvent, setIsHandlingEvent] = useState(false);
    const [isFormLoading, setIsFormLoading] = useState(false);

    const [isQueryResponseShowed, setIsQueryResponseShowed] = useState(false);
    const [isEndedQueryResponsed, setIsEndedQueryResponsed] = useState(false);

    const [userType, setUserType] = useState(""); // user or stakeholder
    const [isDisabled, setIsDisabled] = useState(""); // T or F

    // Home page text design
    const [guideMessage, setGuideMessage] = useState("");
    const [barColor, setBarColor] = useState(colors.barColor);

    // 초성, 중성, 종성 테이블
    const INITIALS = ["ㄱ", "ㄲ", "ㄴ", "ㄷ", "ㄸ", "ㄹ", "ㅁ", "ㅂ", "ㅃ", "ㅅ", "ㅆ", "ㅇ", "ㅈ", "ㅉ", "ㅊ", "ㅋ", "ㅌ", "ㅍ", "ㅎ"];
    // const VOWELS = ["ㅏ", "ㅐ", "ㅑ", "ㅒ", "ㅓ", "ㅔ", "ㅕ", "ㅖ", "ㅗ", "ㅘ", "ㅙ", "ㅚ", "ㅛ", "ㅜ", "ㅝ", "ㅞ", "ㅟ", "ㅠ", "ㅡ", "ㅢ", "ㅣ"];
    const FINALS = ["", "ㄱ", "ㄲ", "ㄳ", "ㄴ", "ㄵ", "ㄶ", "ㄷ", "ㄹ", "ㄺ", "ㄻ", "ㄼ", "ㄽ", "ㄾ", "ㄿ", "ㅀ", "ㅁ", "ㅂ", "ㅄ", "ㅅ", "ㅆ", "ㅇ", "ㅈ", "ㅊ", "ㅋ", "ㅌ", "ㅍ", "ㅎ"];

    const str = "스포츠 강좌이용권에 대해 궁금한 점을 물어보세요..";
    // const str = "테스트..";
    const incrementalArray = createIncrementalArray(str);

    const [index, setIndex] = useState(0); // 배열의 인덱스
    const [blinkNum, setBlinkNum] = useState(0);

    // 한글 자모 분리 함수
    function splitHangul(char) {
        const charCode = char.charCodeAt(0) - 0xac00;
        if (charCode < 0 || charCode > 11171) return [char]; // 한글 범위가 아닐 경우 그대로 반환

        const initialIndex = Math.floor(charCode / 588);
        const vowelIndex = Math.floor((charCode % 588) / 28);
        const finalIndex = charCode % 28;

        const initial = INITIALS[initialIndex];
        // const vowel = VOWELS[vowelIndex];
        const final = FINALS[finalIndex];

        // 받침이 있을 시 [초성, 초성 + 중성, 초성 + 중성 + 종성] 반환
        return final ? [initial, String.fromCharCode(0xAC00 + (initialIndex * 588) + (vowelIndex * 28)), char] : [initial, char];
    }

    // 한글 자모 점진적으로 배열로 만드는 함수
    function createIncrementalArray(str) {
        let result = [];
        let temp = "";

        for (let i = 0; i < str.length; i++) {
            const char = str[i];
            const splitChars = splitHangul(char); // 한글 자모 분리

            splitChars.forEach((c, i) => {
                if (i > 0) {
                    temp = temp.slice(0, -1);
                }
                temp += c;
                result.push(temp);
            });
        }

        return result;
    }

    // Adjust pageH
    const [pageHeight, setPageHeight] = useState(0);
    const pageRef = useRef(null);

    const updateHeight = () => {
        if (pageRef.current) {
            setPageHeight(pageRef.current.scrollHeight);
        }
    };

    useEffect(() => {
        updateHeight();

        // 컴포넌트가 마운트될 때 resize 이벤트 리스너 추가
        window.addEventListener('resize', updateHeight);
        // console.log(pageHeight);

        // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        setPageHeight(window.innerHeight);
    }, [isQueryResponseShowed])

    // Bar Blink
    useEffect(() => {
        if (queryResponses === '') {

            if (index === (incrementalArray.length - 1)) {
                const intervalIdBarBlink = setInterval(() => {
                    setBlinkNum(blinkNum + 1)

                    if (blinkNum === 15) {
                        setBlinkNum(0);
                        setIndex(0);
                        setBarColor(colors.BarColor)
                    }
                    else {
                        if (barColor === "white") {
                            setBarColor('#1E1E1E')
                        }
                        else {
                            setBarColor('white')
                        }
                    }
                }, 500);

                return () => {
                    clearInterval(intervalIdBarBlink);
                };
            }

            else {
                const intervalId = setInterval(() => {
                    setBarColor(colors.BarColor)

                    setGuideMessage(incrementalArray[index]); // 배열에서 문자열 변경

                    // 인덱스를 다음 값으로 변경
                    setIndex((index + 1) % incrementalArray.length); // 배열의 길이를 초과하지 않도록 처리
                }, 100); // 300ms 간격

                return () => clearInterval(intervalId);
            }
        }
    }, [index, blinkNum, isQueryResponseShowed]);

    const togglePostRunWrapper = () => {
        if (!isHandlingEvent) {
            setIsHandlingEvent(true);
            setViewInputQuery(inputQuery);
            setIsEndedQueryResponsed(false);
            setIsQueryResponseShowed(true);

            console.time("executionTime");

            handlePostRun();

            setTimeout(() => setIsHandlingEvent(false), 500);
            setTextQuery("");
            setInputQuery("");
        }
    };

    const handlePostRun = useCallback(async () => {

        await checkExpireTime();

        if (inputQuery === "") {
            toast({
                title: 'Failed',
                description: '질문을 입력해주세요.',
                status: 'error',
                isClosable: true,
                position: 'top',
                zIndex: '999'
            });
            return;
        } else {
            setIsFormLoading(true);

            setQueryResponses(''); // 기존 AI답변 초기화

            // setIsQueryResponseShowed(false);

            try {
                const stream = postRun(inputQuery, userType, isDisabled);

                for await (let token of stream) {

                    console.timeEnd("executionTime")

                    if (!token) {
                        throw new Error("답변 생성 실패")
                    }

                    // console.log(token)

                    else if (token.last_answer === "Y") {
                        setQueryResponses(token.query_response);
                        break;
                    }

                    else if (token.index === 0 && !token.query_response) {
                        console.log("답변 없음")
                        setQueryResponses("해당 내용만으로는 답변을 드릴 수 없습니다. 문장을 다르게 작성하시거나 다른 키워드를 입력해주세요.")
                        // throw new Error("답변 없음")
                    }

                    else if (token.query_response) {
                        setIsFormLoading(false);
                        setQueryResponses(prev => prev + token.query_response)
                    }

                }
                setPostRunTriggered(true);

            } catch (error) {
                setIsQueryResponseShowed(false);
                toast({
                    title: 'Failed',
                    description: '답변 처리 실패',
                    status: 'error',
                    isClosable: true,
                    position: 'top'
                });
            }

            finally {
                // console.timeEnd("executionTime");
                setIsEndedQueryResponsed(true);
                setIsFormLoading(false);
            }
        }
    }, [toast, setPostRunTriggered, checkExpireTime, inputQuery]);


    const setUserCondition = ((option, setOption, thisopt, notthisopt) => {

        if ((option === "") || (option === notthisopt))
            setOption(thisopt)

        else
            setOption("")

    })

    const resetPage = () => {
        setIsQueryResponseShowed(false);
        setInputQuery("");
        setTextQuery("");
        setQueryResponses("");
        setIsEndedQueryResponsed(false);
    }

    useEffect(() => {
        resetPage();
    }, [chatReloadTrigger])

    useEffect(() => {
        if (postRunTriggered) {
            setPostRunTriggered(false);
        }
    }, [postRunTriggered, queryResponses, setPostRunTriggered])

    // When post Button Clicked
    useEffect(() => {
        if (inputQuery !== "") {
            togglePostRunWrapper();
        }
    }, [inputQuery])

    // info changed
    useEffect(() => {
        if ((userType === 'stakeholder') || (userType === ''))
            setIsDisabled('')
    }, [userType])


    // TextInput reset when query responsed
    useEffect(() => {
        setTextQuery("");
    }, [isEndedQueryResponsed])



    return (
        <Container ref={pageRef} maxW="100%" h={pageHeight} bg={colors.ContainerMainBg} minH='95vh' size='container.3xl'>

            <Container
                maxW='container.xl'
                bg={colors.ContainerMainBg}
                color={colors.ContainerPromptColor}
            >
                <Flex direction='column' position='relative' pb='10' h='95vh' >
                    <Container alignContent='center' maxW='' mt='10'>
                        <Box>
                            {isQueryResponseShowed ?
                                <>
                                    <Tabs colorScheme='blue' >
                                        <TabList>
                                            <Tab>문의 내용</Tab>
                                        </TabList>
                                        <TabPanels mt='4' borderRadius='13px'>
                                            {isQueryResponseShowed && (
                                                <TabPanel>
                                                    <Markdown components={ChakraUIRenderer(newTheme)} skipHtml>
                                                        {viewInputQuery}
                                                    </Markdown>
                                                </TabPanel>
                                            )}
                                        </TabPanels>
                                    </Tabs>
                                    <Tabs colorScheme='green' >
                                        <TabList>
                                            <Tab>답변</Tab>
                                        </TabList>
                                        <TabPanels mt='4' borderRadius='13px'>
                                            {isFormLoading && <LoadingOverlay loadingText="질문에 대한 정보를 찾고 있어요" loadingHeight="35vh" />}

                                            {isQueryResponseShowed && (
                                                <TabPanel>
                                                    <Markdown components={ChakraUIRenderer(newTheme)} skipHtml>
                                                        {queryResponses}
                                                    </Markdown>
                                                </TabPanel>
                                            )}
                                        </TabPanels>
                                    </Tabs>
                                </>
                                :
                                <>
                                    <Flex
                                        position="absolute"
                                        top="0"
                                        left="0"
                                        right="0"
                                        bottom="0"
                                        paddingBottom="9rem"
                                        bg={colors.ContainerMainBg}
                                        zIndex="1"
                                        justifyContent="center"
                                        alignItems="center"
                                        borderRadius='16px'
                                    >
                                        <VStack>
                                            <Image
                                                boxSize='5rem'
                                                src='/hodol_face.png'
                                                alt='hodolle'
                                            />
                                            <Box marginTop="0.7rem" display="flex" justifyContent="flex-start" alignItems="center">
                                                <Text as='b' textAlign="center" fontSize="1.5rem">{guideMessage}</Text>

                                                <Box w="0.2rem" h="1.8rem" bg={barColor} marginLeft="0.3rem"></Box>
                                            </Box>

                                            <Card variant="outline" marginTop="1rem" padding="1.5rem" backgroundColor="rgba(0, 0, 0, 0)">
                                                {/* <Text marginBottom="0.3rem" fontSize="0.9rem" width="100%" textAlign="center" as="b" color="#f33101">자주찾는 질문</Text> */}
                                                <HStack gap="2rem" >
                                                    <VStack>
                                                        <ExampleQuestion question={"이용권 신청기간은 언제까지인가요?"} icon={<FcCalendar />} setInputQuery={setInputQuery} />
                                                        <ExampleQuestion question={"지원내용과 대상은 어떻게 되나요?"} icon={<FcDecision />} setInputQuery={setInputQuery} />
                                                        <ExampleQuestion question={"선정기간과 기준을 알려주세요."} icon={<FcTodoList />} setInputQuery={setInputQuery} />
                                                        <ExampleQuestion question={"신청방법이 궁금해요."} icon={<FcFeedback />} setInputQuery={setInputQuery} />
                                                    </VStack>
                                                    <VStack>
                                                        <ExampleQuestion question={"아이디나 비밀번호는 어떻게 찾나요?"} icon={<FcKey />} setInputQuery={setInputQuery} />
                                                        <ExampleQuestion question={"결제 취소 후 재신청하고 싶어요."} icon={<FcRefresh />} setInputQuery={setInputQuery} />
                                                        <ExampleQuestion question={"현장에서 결제할 수 없나요?"} icon={<FcSimCardChip />} setInputQuery={setInputQuery} />
                                                        <ExampleQuestion question={"결제대금은 언제 지급되나요?"} icon={<FcMoneyTransfer />} setInputQuery={setInputQuery} />
                                                    </VStack>
                                                </HStack>
                                            </Card>
                                        </VStack>
                                    </Flex>
                                </>
                            }

                        </Box>
                    </Container>
                    <Spacer />

                    <Container alignContent='center' maxW='100%' mt='10' zIndex="2" bgColor={colors.ContainerMainBg}>
                        {isEndedQueryResponsed &&
                            <HStack spacing="2rem">
                                {(queryResponses.includes("상담센터") || queryResponses.includes("고객센터")) &&
                                    <Card padding="1rem" paddingLeft="2rem" paddingRight="2rem" className={`box ${isEndedQueryResponsed ? 'slide-up' : ''}`}>
                                        <HStack spacing="1.5rem">
                                            <Icon as={PhoneIcon} boxSize={5} />
                                            <Box> 상담센터 <br /> 02-410-1298~9</Box>
                                        </HStack >
                                    </Card>
                                }
                                {queryResponses.includes("홈페이지") &&
                                    <Card padding="1rem" paddingLeft="2rem" paddingRight="2rem" className={`box ${isEndedQueryResponsed ? 'slide-up' : ''}`}
                                        onClick={() => window.open('https://svoucher.kspo.or.kr/main.do')}
                                        _hover={{
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <HStack spacing="1.5rem">
                                            <Icon as={ExternalLinkIcon} boxSize={5} />
                                            <Box >홈페이지 <br /> Link </Box>
                                        </HStack >
                                    </Card>
                                }
                                {queryResponses.includes("지자체") &&
                                    <Card padding="1rem" paddingLeft="2rem" paddingRight="2rem" className={`box ${isEndedQueryResponsed ? 'slide-up' : ''}`}
                                        onClick={() => window.open('https://svoucher.kspo.or.kr/officer/officerList.do?menuNo=14&topMenuNo=3')}
                                        _hover={{
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <HStack spacing="1.5rem">
                                            <Icon as={ExternalLinkIcon} boxSize={5} />
                                            <Box>지자체 담당자 조회 <br /> Link</Box>
                                        </HStack >
                                    </Card>
                                }
                                {queryResponses.includes("신한카드") &&
                                    <Card padding="1rem" paddingLeft="2rem" paddingRight="2rem" className={`box ${isEndedQueryResponsed ? 'slide-up' : ''}`}>
                                        <HStack spacing="1.5rem">
                                            <Icon as={PhoneIcon} boxSize={5} />
                                            <Box>신한카드 고객센터 <br />1544-7000</Box>
                                        </HStack >
                                    </Card>
                                }

                            </HStack>
                        }
                        {!isQueryResponseShowed &&
                            <Box className={"box slide-up"} >
                                <Text as="b" >✔️ 내 정보</Text>
                                <Text as="abbr" fontSize='xs' color='gray' marginLeft="0.5rem">선택해 주시면 더 정확한 정보를 제공할 수 있어요.</Text>
                                <HStack h="2rem" marginTop="0.6rem">
                                    <Button colorScheme={userType === "user" ? 'purple' : 'gray'} onClick={
                                        () => setUserCondition(userType, setUserType, "user", "stakeholder")}> 강좌 이용자 </Button>
                                    <Button colorScheme={userType === "stakeholder" ? 'purple' : 'gray'} onClick={
                                        () => setUserCondition(userType, setUserType, "stakeholder", "user")}> 가맹 시설 관계자</Button>

                                    {userType === "user" && <>
                                        <Divider orientation='vertical' marginLeft="0.3rem" marginRight="0.3rem" className={`box ${userType === "user" ? 'slide-up' : ''}`} />

                                        <Button colorScheme={isDisabled === "false" ? 'purple' : 'gray'} className={`box ${userType === "user" ? 'slide-up' : ''}`} onClick={
                                            () => setUserCondition(isDisabled, setIsDisabled, "false", "true")}> 유·청소년 </Button>
                                        <Button colorScheme={isDisabled === "true" ? 'purple' : 'gray'} className={`box ${userType === "user" ? 'slide-up' : ''}`} onClick={
                                            () => setUserCondition(isDisabled, setIsDisabled, "true", "false")}> 장애인 </Button>
                                    </>}

                                </HStack>
                            </Box>
                        }

                        {(!isQueryResponseShowed || (isEndedQueryResponsed)) &&
                            <>
                                <Flex direction='column' marginTop={0} paddingTop={0}>
                                    <HStack mt='8' marginBottom="1rem">
                                        <Textarea
                                            placeholder="문의하고 싶은 내용을 입력해주세요."
                                            borderRadius='13px'
                                            borderWidth='2px'
                                            bg={colors.ContainerMainBg}

                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    setInputQuery(textQuery);
                                                }
                                            }}
                                            ref={inputRef}
                                            onChange={(e) => { setTextQuery(e.target.value); }}
                                            value={textQuery}
                                        />
                                        <Box position="relative" w="6rem" h="5rem">
                                            <HStack position="absolute" bottom="0">
                                                <IconButton
                                                    borderRadius='13px'
                                                    onClick={() => {
                                                        setInputQuery(textQuery);
                                                    }}
                                                    icon={<IoIosSend />}
                                                    colorScheme='purple'
                                                />
                                                <IconButton
                                                    icon={<RepeatIcon />}
                                                    onClick={() => {
                                                        resetPage();
                                                    }}
                                                    borderRadius='13px'
                                                />
                                            </HStack>
                                        </Box>
                                    </HStack>
                                </Flex>
                                <Center>
                                    <Box>
                                        <Text fontSize='sm' marginBottom="0.5rem">
                                            간단명료한 질문일수록 좋아요. 개인정보를 포함하는 문의는 고객센터 혹은 홈페이지를 이용해주세요.
                                        </Text>
                                    </Box>

                                </Center>
                            </>
                        }
                    </Container>
                </Flex>
            </Container>

        </Container>
    )
}

export default ChatbotPage;



const ExampleQuestion = ({ question, icon, setInputQuery }) => {

    return (
        <Button width="20rem" border='none' justifyContent="flex-start" borderRadius='0.8rem' colorScheme='gray' variant="outline" leftIcon={icon} onClick={() => setInputQuery(question)}> {question} </Button>

    )

}