import {
    Container, Box, IconButton, HStack, Spacer, Text, useColorMode, useColorModeValue,
    Menu, MenuButton, MenuList, MenuItem, useDisclosure, Button,
    MenuDivider, useBreakpointValue, Input, useToast, Image,
} from "@chakra-ui/react";
import { SunIcon, MoonIcon, HamburgerIcon, SettingsIcon, CloseIcon } from '@chakra-ui/icons';
import { useState, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom"; // Import NavLink and useNavigate from react-router-dom
import { IoDocument } from "react-icons/io5";
import { FaRobot, FaSignOutAlt, FaSignInAlt } from "react-icons/fa";
import { useGlobal } from "../contexts/GlobalContext";

import { useRef } from "react";

import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
} from '@chakra-ui/react'

import SignInPage from './SignInPage';

const NavigationBar = ({ checkExpireTime, chatReloadTrigger, setChatReloadTrigger }) => {
    const { toggleColorMode } = useColorMode();
    const [nightMode, setNightMode] = useState(true);
    const navigate = useNavigate();
    const [tokenAndSessionInfo, setTokenAndSessionInfo] = useState({});

    const { isSignedIn, setIsSignedIn } = useGlobal();

    const { isOpen: isControlCenterOpen, onOpen: onControlCenterOpen, onClose: onControlCenterClose } = useDisclosure();

    const toast = useToast();

    // sign in drawer
    const { isOpen: isOpenDrawer, onOpen: onOpenDrawer, onClose: onCloseDrawer } = useDisclosure()
    const btnRef = useRef()

    const handleToggleNightMode = () => {
        setNightMode(!nightMode);
        toggleColorMode();
    };

    const navigationBarBg = useColorModeValue('#e9e9e9', '#333333');
    const navigationBarColor = useColorModeValue('black', 'white');
    const controlCenterBg = useColorModeValue('#333333', '#e9e9e9');
    const controlCenterColor = useColorModeValue('white', 'black');
    const selectedPageColor = useColorModeValue('blue.400', 'orange');

    const [currentPage, setCurrentPage] = useState(window.location.pathname);

    const handleSignOut = () => {
        localStorage.removeItem('access-token');
        localStorage.removeItem('refresh-token');
        localStorage.removeItem('session-exp');
        localStorage.removeItem('access-token-exp');
        localStorage.removeItem('refresh-token-exp');
        setIsSignedIn(false);

        toast({
            title: 'Sign Out Success',
            description: '로그아웃 성공',
            status: 'success',
            isClosable: true,
            position: 'top'
        });

        navigate('/');
    };

    const check = async () => {
        const result = await checkExpireTime();
        if (result === false) {
            navigate('/')
        }
        setTokenAndSessionInfo(result);
    };

    useEffect(() => {
        const handleLocationChange = () => {
            setCurrentPage(window.location.pathname);
        };

        window.addEventListener('popstate', handleLocationChange);

        return () => {
            window.removeEventListener('popstate', handleLocationChange);
        };
    }, []);

    const disappearComponent = useBreakpointValue({ base: true, lg: false });

   

    return (
        <Container
            maxW='100%'
            size="container.3xl"
            h='6vh'
            bg={navigationBarBg}
            color={navigationBarColor}
            zIndex="99"
        >
            <HStack alignItems="center" h="100%">
                <HStack spacing={40}>
                    {/* <Text fontWeight='bold' as={Link} to="/">
                        국민체육진흥공단
                    </Text> */}
                    <Image 
                        // boxSize="4rem"
                        // src= {nightMode ? '/slogan/positive.png' : '/slogan/negative.png'}
                        height="2rem"
                        width="8rem"
                        src="CI.png"
                        onClick={ () => window.location.reload() }
                        cursor="pointer"
                    />
                    {!disappearComponent && (
                        <>
                            {!isSignedIn && (
                                <HStack spacing={10}>
                                    <Button as={Link} to="/" leftIcon={<FaRobot />} bg='transparent'
                                        onClick={
                                            () => {chatReloadTrigger ? setChatReloadTrigger(false) : setChatReloadTrigger(true)}
                                        }
                                    >
                                        Chat
                                    </Button>
                                </HStack>
                            )}

                            {isSignedIn && (
                                <HStack spacing={10}>
                                    <Button as={Link} to="/" leftIcon={<FaRobot />} bg='transparent'
                                        onClick={
                                            () => {chatReloadTrigger ? setChatReloadTrigger(false) : setChatReloadTrigger(true)}
                                        }
                                    >
                                        Chat
                                    </Button>
                                    <Button as={Link} to="/admin" leftIcon={<IoDocument />} bg='transparent'>
                                        Admin
                                    </Button>
                                </HStack>
                            )}
                        </>
                    )}
                </HStack>
                <Spacer />
                <Box>
                    <IconButton
                        icon={isSignedIn ? <FaSignOutAlt /> : <FaSignInAlt />}
                        bg='transparent'
                        ref={btnRef}
                        onClick={isSignedIn ? () => { handleSignOut() } : onOpenDrawer}
                    />
                    <IconButton
                        icon={nightMode ? <MoonIcon /> : <SunIcon />}
                        onClick={handleToggleNightMode}
                        bg='transparent'
                    />
                    {isSignedIn && (
                        <IconButton
                            icon={<SettingsIcon />}
                            onClick={() => {
                                check()
                                if (!isControlCenterOpen) {
                                    onControlCenterOpen();
                                } else {
                                    onControlCenterClose();
                                }
                            }}
                            aria-label="Settings"
                            bg='transparent'
                        />
                    )}

                    <Drawer
                        isOpen={isOpenDrawer}
                        placement='right'
                        onClose={onCloseDrawer}
                        finalFocusRef={btnRef}
                        size='md'
                    >
                        <DrawerOverlay />
                        <DrawerContent bgColor="white">
                            <DrawerCloseButton color="black"
                                _hover={{ backgroundColor: "#EDF2F7" }} />

                            <DrawerBody>
                                <SignInPage onCloseDrawer={onCloseDrawer}/>
                            </DrawerBody>

                            <DrawerFooter>
                                <Button variant='outline' mr={3} onClick={onCloseDrawer}
                                    _hover={{ backgroundColor: "#EDF2F7" }}
                                    backgroundColor="white"
                                    textColor="black"
                                    borderColor="#E2E8F0">
                                    Cancel
                                </Button>
                            </DrawerFooter>
                        </DrawerContent>
                    </Drawer>

                </Box>
            </HStack>
            {isControlCenterOpen && (
                <Box
                    position="fixed"
                    top="16"
                    right="4"
                    width="300px"
                    padding="4"
                    backgroundColor={controlCenterBg}
                    color={controlCenterColor}
                    borderRadius="13px"
                    boxShadow="lg"
                    backdropFilter="blur(10px)"
                    zIndex="10"
                    opacity="1"
                >
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Text fontWeight="bold">Control Center</Text>
                        <IconButton
                            icon={<CloseIcon />}
                            size="sm"
                            onClick={onControlCenterClose}
                            aria-label="Close"
                            backgroundColor="transparent"
                            _hover={{ backgroundColor: "whiteAlpha.300" }}
                        />
                    </Box>
                    <Text mt="2">
                        세션 만료시간
                        <br />
                        {tokenAndSessionInfo.sessionExpireTime ? tokenAndSessionInfo.sessionExpireTime : '세션 만료시간을 확인할 수 없습니다.'}
                    </Text>
                    <Text mt="2">
                        엑세스 토큰 만료시간
                        <br />
                        {tokenAndSessionInfo.accessTokenExpireTime ? tokenAndSessionInfo.accessTokenExpireTime : '엑세스 토큰 만료시간을 확인할 수 없습니다.'}
                    </Text>
                    <Text mt="2">
                        리프레시 토큰 만료시간
                        <br />
                        {tokenAndSessionInfo.refreshTokenExpireTime ? tokenAndSessionInfo.refreshTokenExpireTime : '리프레시 토큰 만료시간을 확인할 수 없습니다.'}
                    </Text>
                </Box>
            )}

        </Container>

    );
};

export default NavigationBar;
