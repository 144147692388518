import React, { useEffect, useState } from "react";
import { Flex, Spinner, Image, Text, HStack, VStack, Box } from "@chakra-ui/react";
import { BeatLoader, BarLoader } from "react-spinners";

const LoadingOverlay = ({ bgColor, loadingText, loadingHeight }) => {
    const [imageSrc, setImageSrc] = useState('/hodol/결심했어.png');
    const [Index, setIndex] = useState(1);

    const prefix = "/hodol/"
    const suffix = ".png"

    const imagesArray_pro = [
        // 'hodol_curious',
        '결심했어',
        '경례',
        '고개 끄덕',
        // '기대',
        // '기대2',
        '서있는 모습',
        '눈찡긋',
        '하트',
        // '브이_백호돌이',
        '빼꼼',
        '고마움',
        // '새해복많이받으세요',
        // '시무룩',
        // '신남1',
        '신남2(투명배경)',
        // '아니요(오른쪽)',
        // '아니요(왼쪽)',
        // '아니요(정면)',
        // '안녕1',
        // '안녕2',
        '운동하기',
        // '울음',
        // '웃음1',
        // '웃음2',
        '응원',
        '좋음1',
        // '좋음2',
        // '축하1',
        '축하2',
        // '커피_백호돌이',
    ];

    const imagesArray = [

        '결심했어',
        '경례',
        '고개 끄덕',

        // '서있는 모습',

        // '하트',

        // '빼꼼',
        // '고마움',

        // '신남2(투명배경)',

        // '운동하기',

        '응원',
        '눈찡긋',
        '좋음1',

        // '축하2',

    ];


    useEffect(() => {
        const intervalId = setInterval(() => {
            setImageSrc(`${prefix}${imagesArray[Index]}${suffix}`)
            setIndex((Index + 1) % imagesArray.length);
        }, 350);

        return () => clearInterval(intervalId);
    }, [Index])

    return (
        <Flex direction='column' position='relative' pb='10' h={loadingHeight}>
        <Flex
            position="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            bg={bgColor}
            zIndex="10"
            justifyContent="center"
            alignItems="center"
            borderRadius='16px'
        >
            <VStack>
                <Box height="13.2rem" paddingTop="2rem">
                    <Image
                        width="13rem"
                        height="auto"
                        src={imageSrc}
                        alt='hodolle'
                    />
                </Box>
                <BarLoader  size="1rem" color="#5b5b5b" cssOverride={ {marginBottom : "1rem"} }/>
                <Text color="darkgray" fontSize="sm">
                    {loadingText || "로딩 메세지"}

                </Text>
                
            </VStack>
        </Flex>
        </Flex>
    );
};

export default LoadingOverlay;
