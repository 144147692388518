import { useBoolean, useEffect, useState, useRef } from "react";
import { useToast, useColorMode } from "@chakra-ui/react";
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
} from '@chakra-ui/react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react'
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
} from '@chakra-ui/react'
import {
    Input, InputGroup, InputLeftElement, Grid, GridItem, Button, ButtonGroup,
    Box, Divider, Icon, IconButton, Card, CardHeader, CardBody, CardFooter, Heading,
    Text, Center, Stack, HStack, VStack, StackDivider, useDisclosure, Container, Badge,
    Radio, RadioGroup
} from '@chakra-ui/react'

import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
    PopoverAnchor,
} from '@chakra-ui/react'

import { ArrowDownIcon, ChevronDownIcon, SearchIcon } from '@chakra-ui/icons'
import { IoMdArrowDropdown } from "react-icons/io";
import { LiaUndoAltSolid } from "react-icons/lia";
import { getData } from "../Api";
import { usePageColor } from '../contexts/ColorContext';

import Pagination from "../components/PaginationComponent";
import LoadingOverlay from '../components/LoadingOverlayComponent';



const AdminPage = () => {

    const toast = useToast();

    const tableCaption = "국민체육진흥공단";

    // Raw data
    const [originData, setOriginData] = useState(null);
    const [newData, setNewData] = useState(null);

    // Current data state
    const [currentDataType, setCurrentDataType] = useState();
    const [currentData, setCurrentData] = useState([]);

    // Button variant
    const [allButtonVar, setallButtonVar] = useState('solid');
    const [originButtonVar, setOriginButtonVar] = useState('outline');
    const [chatButtonVar, setChatButtonVar] = useState('outline');

    // Pagination
    const [selectedPage, setSelectedPage] = useState(1);
    const [pagenationLength, setPagenationLength] = useState(20);

    // Bright/Dark mode recognization
    const { colorMode } = useColorMode();

    // To control modal 
    const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();
    const [modalContent, setModalContent] = useState({ 'id': 0, 'question': 0, 'answer': 0, 'dt': 0, 'label': 0 });

    // View page at a time
    const viewPageNumArr = [5, 10, 20, 50, 100];

    // To search value
    const [searchValue, setSearchValue] = useState('')

    // Loading spinner
    const [isFormLoading, setIsFormLoading] = useState(false);
    const colors = usePageColor('chatbot');

    // ResColumn popover
    const [resColumn, setResColumn] = useState('All');
    const [selectedLabel, setSelectedLabel] = useState('All');
    const popoverRef = useRef();

    const radioOptions_user = [
        { value: '강좌이용', label: '강좌이용' },
        { value: '회원정보', label: '회원정보' },
        { value: '이용자 신청', label: '신청' },
        { value: '카드', label: '카드' },
        { value: '이용자 기타', label: '기타' }
    ];

    const radioOptions_stakeholder = [
        { value: '강좌관리', label: '강좌관리' },
        { value: '결제', label: '결제' },
        { value: '시설 신청', label: '신청' },
        { value: '시설정보관리', label: '시설정보관리' },
        { value: '시설 기타', label: '기타' }
    ];

    // data download fucntion
    const dataDownLoad = () => {
        // 1. 기존 데이터를 CSV 형식으로 변환
        let data = [
            ["VOC_ID(PK)", "VOC_CTNT_MERGE", "Cleaned_VOC_REPLY", "REG_DT", "Res_Column"]
        ];

        data = [...data, ...currentData]

        let csvContent = "";

        // 각 배열을 쉼표로 구분해 CSV 형태로 변환
        data.forEach(function (rowArray) {
            // 필드 내 쉼표로 column 분리, \n로 row 분리되는 현상 제거
            rowArray = rowArray.map(field => {
                // 필드 (특히 RES_COLUMN) 가 비어있지 않을 시
                if (field) {
                    // 필드에 쉼표, 줄바꿈 또는 큰따옴표가 있으면 필드를 큰따옴표로 감싼다
                    if (field.includes(",") || field.includes("\n") || field.includes('"')) {
                        // 필드 내의 기존 큰따옴표는 이스케이프 처리하기 위해 두 개로 바꾼다
                        field = `"${field.replace(/"/g, '""')}"`;
                    }
                    return field
                }
            })

            let row = rowArray.join(","); // 쉼표로 열 구분
            csvContent += row + "\r\n"; // 줄바꿈 추가
        });

        // 2. UTF-8 BOM 추가
        const bom = "\uFEFF"; // BOM 추가

        // 3. Blob 생성 (BOM + CSV 내용)
        const blob = new Blob([bom + csvContent], { type: "text/csv;charset=utf-8;" });

        // 4. Blob을 이용해 다운로드 링크 생성
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);

        // 5. 링크에 Blob URL 설정 및 다운로드 파일명 설정
        link.setAttribute("href", url);
        link.setAttribute("download", "data.csv");

        // 6. 링크를 클릭하여 다운로드
        document.body.appendChild(link);
        link.click();

        // 7. 링크 제거
        document.body.removeChild(link);
    }

    const dataFilter = () => {
        setSelectedPage(1);

        let filteredData;

        if (currentDataType === "all") {
            filteredData = [...newData, ...originData]
        } else if (currentDataType === "origin") {
            filteredData = originData
        } else {
            filteredData = newData
        }

        // 1. 첫 번째 필터: 데이터 타입별 필터링 (all, origin, new)
        if (searchValue) {
            if (currentDataType === "all") {
                // filteredData = [...newData, ...originData].filter(innerArray =>
                //     innerArray.some(str => str && str.includes(searchValue))
                // );
                filteredData = filteredData.filter(innerArray =>
                    innerArray.some(str => str && str.includes(searchValue))
                );
            } else if (currentDataType === "origin") {
                // filteredData = originData.filter(innerArray =>
                //     innerArray.some(str => str && str.includes(searchValue))
                // );
                filteredData = filteredData.filter(innerArray =>
                    innerArray.some(str => str && str.includes(searchValue))
                );
            } else {
                // filteredData = newData.filter(innerArray =>
                //     innerArray.some(str => str && str.includes(searchValue))
                // );
                filteredData = filteredData.filter(innerArray =>
                    innerArray.some(str => str && str.includes(searchValue))
                );
            }
        }

        // 2. 두 번째 필터: Label 기준 필터링
        if (resColumn !== "All") {
            if (radioOptions_user.some(option => option.value === resColumn)) {
                if (resColumn === "이용자 신청") {
                    filteredData = filteredData.filter(
                        subArray => subArray[4] === "이용자 - 신청"
                    );
                }
                else if (resColumn === "이용자 기타") {
                    filteredData = filteredData.filter(
                        subArray => subArray[4] === "이용자 - 기타"
                    );
                }
                else {
                    filteredData = filteredData.filter(
                        subArray => subArray[4] === "이용자 - " + resColumn
                    );
                }

            } else {
                if (resColumn === "시설 신청") {
                    filteredData = filteredData.filter(
                        subArray => subArray[4] === "시설 - 신청"
                    );
                }
                else if (resColumn === "시설 기타") {
                    filteredData = filteredData.filter(
                        subArray => subArray[4] === "시설 - 기타"
                    );
                }
                else {
                    filteredData = filteredData.filter(
                        subArray => subArray[4] === "시설 - " + resColumn
                    );
                }
            }
        }

        // 3. 최종적으로 상태 업데이트
        setCurrentData(filteredData);
        console.log(resColumn);
    };

    // Update selectedlabel
    useEffect(() => {
        setSelectedLabel(resColumn);
    }, [resColumn]);


    useEffect(() => {
        (async () => {
            try {
                setIsFormLoading(true);

                const res = await getData()
                setOriginData(Object.values(res['old_data']))
                setNewData(Object.values(res['new_data']))
                setCurrentDataType('all')

            } catch (error) {

                if ((error.response.status === 401) || (error.response.status === 500)) {
                    console.error("Error:", error.message);
                    toast({
                        title: 'Failed',
                        description: '로그인이 필요합니다.',
                        status: 'error',
                        isClosable: true,
                        position: 'top',
                        zIndex: '999'
                    });

                    // 홈페이지로 이동
                    window.location.href = '/';
                }

            } finally {
                setIsFormLoading(false);
            }
        })();
    }, [toast]);


    // current data 변경 시
    useEffect(() => {
        if (originData) {
            setSelectedPage(1);
            if (currentDataType == "all") {
                // 버튼 색상 변경
                setallButtonVar('solid')
                setOriginButtonVar('outline')
                setChatButtonVar('outline')
                setCurrentData([...newData, ...originData])
            }
            else if (currentDataType == "origin") {
                setallButtonVar('outline')
                setOriginButtonVar('solid')
                setChatButtonVar('outline')
                setCurrentData(originData)
            }
            else {
                setallButtonVar('outline')
                setOriginButtonVar('outline')
                setChatButtonVar('solid')
                setCurrentData(newData)
            }
            setResColumn('All');
            // dataFilter();
        }
    }, [currentDataType]);

    // TextHighlighter
    const getHighlightedText = (text, highlight) => {
        if (!highlight) {
            return text;
        }

        text = String(text);

        const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
        return parts.map((part, index) =>
            part.toLowerCase() === highlight.toLowerCase() ? (
                <span key={index} style={{ backgroundColor: 'rgba(255, 255, 0, 0.5)' }}>
                    {part}
                </span>
            ) : (
                part
            )
        );
    };


    return (
        <Container maxW='full'>
            <Box w='100%' minH='100vh' position='relative'>
                {isFormLoading ? <LoadingOverlay loadingText="데이터를 불러오고 있어요" loadingHeight="60vh" />
                    : <>
                        <Grid templateColumns='repeat(1, 1fr)' paddingTop="2%" paddingLeft="2%" paddingRight="2%">

                            <GridItem>
                                <Button colorScheme='blue' onClick={() => setCurrentDataType('all')} variant={allButtonVar} marginTop="1rem" marginRight="1rem">
                                    All
                                </Button>
                                <Button colorScheme='blue' onClick={() => setCurrentDataType('origin')} variant={originButtonVar} marginTop="1rem" marginRight="1rem">
                                    Origin Data
                                </Button>
                                <Button colorScheme='blue' onClick={() => setCurrentDataType('new')} variant={chatButtonVar} marginTop="1rem" marginRight="1rem">
                                    Chat Data
                                </Button>
                            </GridItem>
                            <GridItem colStart={2} >
                                <Button leftIcon={<ArrowDownIcon />} onClick={dataDownLoad} marginTop="1rem" colorScheme='blue' variant='ghost'>
                                    Download Data
                                </Button>
                            </GridItem>
                        </Grid>
                        <Grid templateColumns='repeat(2, 1fr)' paddingTop="1%" paddingLeft="2%" paddingRight="2%">
                            <GridItem colStart={1}>
                                <InputGroup>
                                    <InputLeftElement pointerEvents='none'>
                                        <SearchIcon color='gray.300' />
                                    </InputLeftElement>
                                    <Input placeholder='Search data' value={searchValue}
                                        onChange={(e) => { setSearchValue(e.target.value) }}
                                        onKeyUp={(e) => {
                                            if (e.key == "Enter") dataFilter();
                                        }
                                        }
                                    />
                                </InputGroup>
                            </GridItem>
                            <GridItem colStart={2} >
                                <Button colorScheme='blue' marginLeft="0.5rem" onClick={() => dataFilter()}>
                                    Search
                                </Button>
                                <IconButton variant='ghost' icon={<LiaUndoAltSolid />} marginLeft="0.25rem" onClick={() => {
                                    setSearchValue('');
                                    setResColumn('All');
                                    // 데이터 초기화
                                    if (currentDataType == "all") {
                                        setCurrentData([...newData, ...originData])
                                    }
                                    else if (currentDataType == "origin") {
                                        setCurrentData(originData)
                                    }
                                    else {
                                        setCurrentData(newData)
                                    }
                                }} />
                            </GridItem>
                            <GridItem colStart={3} >
                                <Menu>
                                    <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                                        View {pagenationLength} at a time
                                    </MenuButton>
                                    <MenuList>
                                        {viewPageNumArr.map((num) => {
                                            return <MenuItem onClick={() => { setPagenationLength(num); setSelectedPage(1); }}>View {num} at a time</MenuItem>
                                        })}
                                    </MenuList>
                                </Menu>
                            </GridItem>
                        </Grid>
                        <Text color='gray.300' marginLeft="2%" marginTop="1%" > {currentData.length} results </Text>

                        <TableContainer padding="1rem">
                            <Table variant='simple' width="100%" height="auto" style={{ "table-layout": "fixed" }}>
                                <TableCaption> {tableCaption} </TableCaption>
                                <Thead>
                                    <Tr width="100%">
                                        <Th width="10%" wordBreak="break-all" style={{ "table-layout": "fixed" }}>VOC_ID(PK)</Th>
                                        <Th width="30%" wordBreak="break-all" style={{ "table-layout": "fixed" }}>VOC_CTNT_MERGE</Th>
                                        <Th width="30%" wordBreak="break-all" style={{ "table-layout": "fixed" }}>Cleaned_VOC_REPLY</Th>
                                        <Th width="10%" wordBreak="break-all" style={{ "table-layout": "fixed" }}>REG_DT</Th>
                                        <Th width="15%" wordBreak="break-all" style={{ "table-layout": "fixed" }}
                                            _hover={{
                                                bg: colorMode === 'dark' ? '#444A67' : '#ebf8ff',
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => { popoverRef.current.click(); }}
                                        >
                                            <Popover>
                                                <PopoverTrigger>
                                                    <HStack>
                                                        <Text ref={popoverRef} >Res_Column</Text>
                                                        <IoMdArrowDropdown size="1rem" />
                                                        <Text as="i" color="gray"> {"(" + selectedLabel + ")"} </Text>
                                                    </HStack>
                                                </PopoverTrigger>
                                                <RadioGroup onChange={setResColumn} value={resColumn}>
                                                    <PopoverContent padding="1rem" boxSize="100%">
                                                        <PopoverArrow />
                                                        <PopoverCloseButton />
                                                        <PopoverHeader>RES_COLUMN</PopoverHeader>
                                                        <PopoverBody>
                                                            <Radio size='sm' value='All'>All</Radio>
                                                        </PopoverBody>
                                                        <PopoverHeader marginTop="0.5rem" size='md'>이용자</PopoverHeader>
                                                        <PopoverBody>
                                                            <Box>
                                                                {radioOptions_user.map((option, index) => (
                                                                    <Radio
                                                                        size="sm"
                                                                        value={option.value}
                                                                        marginRight="0.5rem"
                                                                    >
                                                                        {option.label}
                                                                    </Radio>
                                                                ))}

                                                            </Box>
                                                        </PopoverBody>
                                                        <PopoverHeader marginTop="0.5rem">시설</PopoverHeader>
                                                        <PopoverBody>
                                                            <Box>
                                                                {radioOptions_stakeholder.map((option, index) => (
                                                                    <Radio
                                                                        size="sm"
                                                                        value={option.value}
                                                                        marginRight="0.5rem"
                                                                    >
                                                                        {option.label}
                                                                    </Radio>
                                                                ))}

                                                            </Box>
                                                        </PopoverBody>
                                                        <PopoverFooter display='flex' justifyContent='flex-end' marginTop="2rem">
                                                            <ButtonGroup size='sm'>
                                                                <Button variant='outline'>Cancel</Button>
                                                                <Button
                                                                    colorScheme='blue'
                                                                    onClick={
                                                                        () => dataFilter()
                                                                    }
                                                                >Apply</Button>
                                                            </ButtonGroup>
                                                        </PopoverFooter>
                                                    </PopoverContent>
                                                </RadioGroup>

                                            </Popover>
                                        </Th>
                                        <Th width="15%" wordBreak="break-all" style={{ "table-layout": "fixed" }}>Type</Th>
                                    </Tr>
                                </Thead>
                                <Tbody width="100%">
                                    {originData ? // 데이터 로딩 완료 후 실행
                                        currentData.slice((selectedPage - 1) * pagenationLength, selectedPage * pagenationLength).map((datas, i) => (
                                            <Tr width="100%"
                                                _hover={{
                                                    bg: colorMode === 'dark' ? '#444A67' : '#ebf8ff',
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => {
                                                    setModalContent({ 'id': datas[0], 'question': datas[1], 'answer': datas[2], 'dt': datas[3], 'label': datas[4], 'type': datas[5] });
                                                    onModalOpen();
                                                }}>

                                                {datas.map((data, i) =>
                                                    <Td height="auto" wordBreak="break-all" overflow="hidden" textOverflow="ellipsis">{data}</Td>
                                                )}

                                            </Tr>
                                        ))
                                        : <></>
                                    }
                                </Tbody>
                            </Table>
                            <Center mt='3'>
                                <Pagination selectedPage={selectedPage} setSelectedPage={setSelectedPage} allRowsNumber={currentData.length} rowsNumberPerPage={pagenationLength} />
                            </Center>
                            <Modal
                                isCentered
                                onClose={onModalClose}
                                isOpen={isModalOpen}
                                motionPreset='slideInBottom'
                                size="5xl"
                            >
                                <ModalOverlay />
                                <ModalContent >
                                    <ModalHeader > </ModalHeader>
                                    <ModalCloseButton />
                                    <ModalBody>
                                        <Card variant="unstyled" margin="1rem" bgColor='rgba(0, 0, 0, 0.0)' >
                                            <CardHeader>
                                                <HStack marginBottom="2rem">
                                                    <Text fontSize='2xl' as="b" marginRight="1rem" marginBottom="0.45rem">문의 상세 내역</Text>
                                                    <Badge fontSize='sm'><p>{getHighlightedText(modalContent.id, searchValue)}</p></Badge>
                                                    <Badge fontSize='sm'><p>{getHighlightedText(modalContent.dt, searchValue)}</p></Badge>
                                                    <Badge fontSize='sm'><p>{getHighlightedText(modalContent.label, searchValue)}</p></Badge>
                                                    <Badge fontSize='sm'><p>{getHighlightedText(modalContent.type, searchValue)}</p></Badge>
                                                </HStack>
                                            </CardHeader>
                                            <CardBody>
                                                <Stack divider={<StackDivider />} spacing='5'>
                                                    <Box>
                                                        <Heading size='md' textTransform='uppercase'>
                                                            문의 내용
                                                        </Heading>
                                                        <Text pt='2' fontSize='s'>
                                                            {/* {modalContent.question} */}
                                                            <p>{getHighlightedText(modalContent.question, searchValue)}</p>
                                                        </Text>
                                                    </Box>
                                                    <Box>
                                                        <Heading size='md' textTransform='uppercase'>
                                                            답변
                                                        </Heading>
                                                        <Text pt='2' fontSize='s'>
                                                            {/* {modalContent.answer} */}
                                                            <p>{getHighlightedText(modalContent.answer, searchValue)}</p>
                                                        </Text>
                                                    </Box>

                                                </Stack>
                                            </CardBody>
                                        </Card>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button colorScheme='blue' onClick={onModalClose}>
                                            Close
                                        </Button>
                                    </ModalFooter>
                                </ModalContent>
                            </Modal>

                        </TableContainer>
                    </>
                }
            </Box>

        </Container >
    );
};

export default AdminPage;